import React from 'react';
import { Routes, Route } from "react-router-dom";
import PrivateRoute from './helpers/PrivateRoute';
import { CadastroUsuario } from './pages/CadastroUsuario';
import { Equipamento } from './pages/Equipamentos';

import { Home } from './pages/Home';
import { Local } from './pages/Local';
import { Login } from './pages/Login';
import { NovaReserva } from './pages/NovaReserva';

function App() {

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/session-expired" element={<Login />} />
      <Route path="/home"
        element={<PrivateRoute><Home /></PrivateRoute>}
      />
      <Route path="/nova-reserva"
        element={<PrivateRoute><NovaReserva /></PrivateRoute>}
      />
      <Route path="/local"
        element={<PrivateRoute><Local /></PrivateRoute>}
      />
      <Route path="/equipamentos"
        element={<PrivateRoute><Equipamento /></PrivateRoute>}
      />
      <Route path="/cadastro-usuario"
        element={<PrivateRoute><CadastroUsuario /></PrivateRoute>}
      />
    </Routes>
  );
}

export default App;
