import styled from "styled-components";

export const Container = styled.div`
  background-image: ${(props) => props.theme.navBar.backgroundColor};
  box-shadow: rgba(0, 0, 0, 0.24) 3px 3px 8px;

  width: 100%;
  height: ${(props) => props.theme.mensures.nav_bar_height};
  position: fixed; 
  z-index: 99;

  .ies-logo { 
    grid-area: ies-logo;
    padding: 5px;
    h1 {
      font-size: 30px;
      font-weight: 300;
      color: #ffffff;
      padding-top: 10px;
    }
    img {
      height: 33px;
      margin-top: -28px;
      margin-left: 25px;
    }
  }

  .button-logout {
    grid-area: button-logout;
    display: flex;
    align-items: center;
    button {
      background-color: transparent;
      color: #fff;
      width: 40px;
      border: 1px solid #fff;

      i {
        font-size: 30px;
      }

      :hover {
        background-color: #fff;
        color: #000;
      }
    }

  }

  .content {
    grid-area: content;
  }
  .content-navbar {
    width: 100%;
    height: 100vh;
    background-color: rgba(150, 150, 150, 0.5);
    position: absolute;
    top: 92px;
    right: 0;
    z-index: 99;
    
  }

 .help{
 margin-left:30px;  

  .link_help{
    color: #ffffff;
  }
 }


  .navbar {
    background-color: #faffff;
    width: 300px;
    height: 100vh;
    justify-content: left;
    position: absolute;
    top: 0;
    right: 0;
  
  }
  .navbar-close {
    font-size: 30px;
    position: absolute;
    top: 0;
    left: 15px;
    color: #b0bec5;
    cursor: pointer;
  }

  ul {
    position: absolute;
    top: 40px;
  }

  ul li {
    list-style: none;
    margin-bottom: 10px;
    cursor: pointer;
    
  }
  ul li:nth-of-type(1) {
    cursor: context-menu;
  }

  .menu-navbar-link {
    color: black;
    text-decoration: none;
  
  }
  .bi-question-circle{
    font-weight: bold;
  }
  .menu-adim {
    width: 100%;
    position: absolute;
    top: 120px;
    padding-top: 10%;
    border-top: 1px solid #cfd8dc;
  }

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto 4fr;
  grid-template-areas: "ies-logo content button-logout";

  justify-items: center;
  align-items: center;
`;
