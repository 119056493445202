import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { ButtonComponent } from '../../components/ButtonComponent';
import { AppContext } from '../../context/ContextProvider';
import { Container } from './styles';
//import logo_docs from '../../assets/unileao/logo-docs.png';
import { Spinner } from 'react-bootstrap';

interface ILoginInputs {
  user: string;
  password: string;
}

const Login: React.FC = () => {

  const theme = useTheme();
  const { setUser } = useContext(AppContext);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ILoginInputs>();


  const [isLoading, setIsLoading] = useState(false);

  const { handleLogin } = useContext(AppContext);

  async function onSubmit(data: ILoginInputs) {
    setIsLoading(true);
    await handleLogin(data.user, data.password);
    setIsLoading(false);
  }

  useEffect(() => {
    if (window.location.pathname === "/session-expired") {
      setUser(null);
      sessionStorage.removeItem(String(process.env.REACT_APP_SESSION_KEY));
      alert("Sessão expirada, faça login novamente.");
    }
  }, [])

  return (
    <Container>
      <div id="logo-branca">
        <img src="https://unileao.edu.br/wp-content/themes/portalv2.0/img/logo20anos.svg" alt={`Logo ${theme.general.ies_name}`} />
      </div>

      <div id="form">
        <h1>Audiovisual</h1>

        <form onSubmit={handleSubmit(onSubmit)}>

          <div className="input-login-form">
            <label>Usuário</label>
            <input type="text" id="login-user"
              {...register("user", {
                required: "Campo obrigatório",
              })} />
          </div>

          <div className="input-login-form">
            <label>Senha</label>
            <input type="password" id="login-password"
              {...register("password", {
                required: "Campo obrigatório",
              })} />
          </div>

          <ButtonComponent type="submit" variant="primary" minWidth="100%" minHeight="50px">
            {
              isLoading ?
                <Spinner animation="border" />
                :
                <>
                  Entrar
                </>
            }
          </ButtonComponent>
        </form>
      </div>
    </Container>
  );
}

export { Login };
