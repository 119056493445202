import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTheme } from "styled-components";
import { AppContext, EUserRole } from "../../context/ContextProvider";
import { ButtonComponent } from "../ButtonComponent";
import { Container } from "./styles";

const NavBar = () => {
  const { handleLogOut, user } = useContext(AppContext);

  const theme = useTheme();

  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  return (
    <Container>
      <div className="ies-logo">
        <h1>Audiovisual</h1>
        <img
          src="https://unileao.edu.br/wp-content/themes/portalv2.0/img/logo20anos.svg"
          alt={`Logo ${theme.general.ies_name}`}
        />
      </div>
      <div className="content"></div>
      <div className="button-logout">
        <ButtonComponent type="button" onClick={showSidebar}>
          <i className="bi bi-list"></i>
        </ButtonComponent>
       
       
        <div className="help">
      <a href="https://api.whatsapp.com/message/3XL44ILSWSTQF1?autoload=1&app_absent=0"
        target="_blank"
        className="menu-navbar-link link_help"
        >
        <i className="bi bi-question-circle"></i> Ajuda
      </a>
      </div>

      
      </div>
    

      {sidebar && (
        <div className="content-navbar">
          <nav className="navbar">
            <i onClick={showSidebar} className="navbar-close bi bi-x"></i>

            <ul>
              <li>{user?.name}</li>
              <li onClick={handleLogOut}>
                Sair <i className="bi bi-door-open-fill"></i>
              </li>
            </ul>

            {
           (user !== null && user.role === EUserRole["Administrador"]) ?
              <>
                <ul className="menu-adim">
                  <li>
                    <NavLink
                      className="menu-navbar-link"
                      to={"/home"}
                      onClick={showSidebar}
                    >
                      <i className="bi bi-calendar-week"></i> Reservas
                    </NavLink>
                  </li>
                  <li>
                  <NavLink
                      className="menu-navbar-link"
                      to={"/cadastro-usuario"}
                      onClick={showSidebar}
                    >
                    <i className="bi bi-people-fill"></i> Usuários
                    </NavLink>
                    </li> 
                  <li>
                    <NavLink
                      className="menu-navbar-link"
                      to={"/local"}
                      onClick={showSidebar}
                    >
                      <i className="bi bi-building"></i> Locais
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="menu-navbar-link"
                      to={"/equipamentos"}
                      onClick={showSidebar}
                    >
                      <i className="bi bi-megaphone-fill"></i> Equipamentos
                    </NavLink>
                  </li>
                </ul>
              </>
                :
                <>
                  <ul className="menu-adim">
                    <li>
                      <NavLink
                        className="menu-navbar-link"
                        to={"/home"}
                        onClick={showSidebar}
                      >
                        <i className="bi bi-calendar-week"></i> Reservas
                      </NavLink>
                    </li>
                    <li>
                      <a
                        href="https://api.whatsapp.com/message/3XL44ILSWSTQF1?autoload=1&app_absent=0"
                        target="_blank"
                        className="menu-navbar-link"
                        onClick={showSidebar}
                      >
                        <i className="bi bi-question-circle"></i> Ajuda
                      </a>
                    </li>
                  </ul>
                </>
            }
          
          </nav>
        </div>
      )}
    </Container>
  );
};

export { NavBar };
