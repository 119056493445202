import styled from "styled-components";

export const Container = styled.div`

    width: 300px;
    height: 415px;

    display: flex;
    flex-direction: column;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 4px;

    margin-right: 15px;
    margin-bottom: 15px;


    #header{
        width: 100%;

        
        border-radius: 4px 4px 0 0;
        color: #FFF;

        
        display: flex;
        justify-content: flex-start;
        align-items: center;


        h1{
            font-size: 20px;
            margin-top: 5px;
        }

        div{
            width: 100%;
            border-radius: 4px 4px 0 0;
        }

        #header_1{
            padding: 2px 15px; 
            background-color: #FFCA28;
           
        }

        #header_2{
            width: 100%;
            padding: 2px 15px; 
            background-color: #1976D2;
      
        }

        #header_3{
            width: 100%;
            padding: 2px 15px; 
            background-color: #26A69A;
    
        }

        
        #header_4{
            width: 100%;
            padding: 2px 15px; 
            background-color: #90A4AE;
    
        }

    }


    #dados-reserva{
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;

        padding: 5px 25px;

    }

    .horarios{
        margin-bottom: 15px;
    }
    .horarios_p{
        width: 40%;
        display: inline-block;
        background: white;
        margin-right: 10px;
        line-height: 1px;
    }

    .horarios_modal{
        width: 30%;
        display: flex;
        align-items: center;
    }

    .horarios-div{
        display: flex;
        margin-top: 10px;
    }
    .data_reserva{
        width: 70%;
        display: flex;
        justify-content: space-between;
    }

    .button{
        display: flex;
        justify-content: right;
    }

    .button-Montado-horario{
        background-color: #26a69a;
        border: 0;
    }

    .button-Montado-horario_dois{
        border:0;
        padding-left: 22px;
        padding-right: 22px;
        background-color: #90A4AE;
        cursor: not-allowed;
    }

    .button_Montado{
        justify-content: space-between; 
        display: flex;

        p{        
            position: relative;
            top: 8px;
            margin-left: 50px;
        }
    }

    .button-Fechado {
        background-color: #26A69A;
        border: 0;
        margin-right: 10px;
      }
      
    .button-Fechado_dois{
        background-color: #90A4AE;
        border: 0;
        margin-right: 10px;
        cursor: not-allowed;
    }


    

    #footer{
        width: 100%;
        height: auto;

        display: flex;
        justify-content: right;
        align-items: center;

        padding: 10px;
        margin-top: -17px;

        .button-detalhes{
            width: auto;
            padding: 6px;
            background-color: #001E47;
            border: 0;
        }



    }


    @media screen and (max-width: 930px){
         min-width: none;

    }
    
`;