import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup, Toast } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTheme } from "styled-components";
import Swal from "sweetalert2";
import { CardListaItens } from "../../components/CardListaItens";
import { ContainerPesquisa } from "../../components/ContainerPesquisa";
import { HeaderPage } from "../../components/HeaderPage";
import { MainContainer } from "../../components/MainContainer";
import { ModalCadastro } from "../../components/ModalCadastro";
import { EUserRole, IUser } from "../../context/ContextProvider";
import { api } from "../../services/ApiService";
import { Container } from "./styles";

interface IInputsCadastroUsuario {
  id: string;
  name: string;
  email: string;
  codusuario: string;
  role: EUserRole;
}

const CadastroUsuario = () => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    getValues,
    reset: resetForm
  } = useForm<IInputsCadastroUsuario>();
  const theme = useTheme();

  // const [usuario, setUsuario] = useState(usersMock);
  const [usuario, setUsuario] = useState<IInputsCadastroUsuario[]>([]);
  const [usuarioFiltro, setUsuarioFiltro] = useState("");
   
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showCadastroUsuarioModal = () => setIsModalVisible(!isModalVisible);
  //const [userToUpdate, setUserToUpdate] = useState<IUser | null>(null);
  
  function handleUpdate(user: IUser) {
    setValue("id", user.id || "");
    setValue("codusuario", user.codusuario);
    setValue("email", user.email);
    setValue("name", user.name);
    setValue("role", user.role);
    showCadastroUsuarioModal();
  }

  async function onSubmit(data: IInputsCadastroUsuario) {

    let request_params = {
      success_message: (!data.id ? "Cadastro realizado com sucesso." : "Cadastro atualizado com sucesso"),
      url: (!data.id ? "/usuario/novo" : "/usuario/atualizar")
    }
   
    try {

      const result = await api.post(request_params.url, {
        ...data
      })

      Swal.fire({
        toast: true,
        icon: "success",
        title: "Feito!",
        text: request_params.success_message,
        confirmButtonText: "Aceitar",
        confirmButtonColor: theme.button_colors.primary,
      });
      resetForm()
      getUsuario();
      {request_params.success_message == "Cadastro atualizado com sucesso"? showCadastroUsuarioModal() : <></>}
    } catch (err: AxiosError | any) {
      console.log(err)
      Swal.fire({
        toast: true,
        icon: "warning",
        title: "Atenção!",
        text: err.response ? err.response.data.error : "Falha ao tentar comunicar-se com o servidor, por favor tente novamente, se o erro persistir contate o administrador do sistema.",
        confirmButtonText: "Aceitar",
        confirmButtonColor: theme.button_colors.primary,
      });
    }
  }

  async function getUsuario() {
    try {
      const result = await api.get("/usuario/all");
      setUsuario(result.data)
    } catch (err: AxiosError | any) {
      Swal.fire({
        title: "Atenção!",
        icon: "warning",
        text: err.response ? err.response.data.error : "Erro desconhecido. Por favor contate o administrador do sistema.",
        confirmButtonColor: theme.button_colors.primary,
        toast: true
      })
    }
    
  }

  async function deleteUsuario(id_usuario: string) {
    let isloading = false;

    await Swal.fire({
      title: "Atenção!",
      icon: "question",
      text: `Tem certeza que deseja excluir esse usuário?`,
      confirmButtonColor: theme.button_colors.primary,
      confirmButtonText: "Sim",
      denyButtonText: "Não",
      denyButtonColor: theme.button_colors.danger,
      showDenyButton: true,
      toast: true,
      preConfirm: () => executeDelete(),
    })

    async function executeDelete() {
      isloading = true;

      try {
        await api.delete(`/usuario/${id_usuario}/delete`);

        Swal.fire({
          title: "Feito!",
          icon: "success",
          text: "Usuário apagado com sucesso",
          confirmButtonColor: theme.button_colors.primary,
          confirmButtonText: "Aceitar",
          toast: true,
        })
        getUsuario();
      } catch (err: AxiosError | any) {
        Swal.fire({
          title: "Atenção!",
          icon: "warning",
          text: err.response ? "Usuário não pode ser deletado!" : "Erro desconhecido. Por favor contate o administrador do sistema.",
          confirmButtonColor: theme.button_colors.primary,
          toast: true
        })
        isloading = false;
      }
    }

  }

  useEffect(() => {
    getUsuario();
  }, [])


  return (
    <MainContainer>
      <Container>
        <HeaderPage title="Usuários">
          <Button id="button" onClick={showCadastroUsuarioModal}>
            Novo usuário <i className="bi bi-plus-circle-dotted"></i>
          </Button>
        </HeaderPage>
        <ContainerPesquisa title={"Pesquisar usuário"} haveresearch>
          <InputGroup className="mb-3">
            <Form.Control
              id="input-pesquisa"
              placeholder="Digite algo para procurar..."
              value={usuarioFiltro}
              onChange={(ev) => setUsuarioFiltro(ev.target.value)}
            />
            <InputGroup.Text id="basic-addon2">
              <i className="bi bi-search"></i>
            </InputGroup.Text>
          </InputGroup>
          <div className="lista">
            {usuario.length > 0 ?
              usuario.filter((item) => 
              item.name.toLowerCase().includes(usuarioFiltro.toLowerCase()) || item.role.toLowerCase().includes(usuarioFiltro.toLowerCase()) )
              .map((item) => {
                return <CardListaItens
                  key={item.id}
                  titulo1="Usuário:"
                  titulo2="Permissão:"
                  texto1={item.name}
                  texto2={item.role}
                >
                  <Button className="button-apagar" variant="outline-danger"
                    onClick={() => deleteUsuario(item.id)}>
                    Apagar <i className="bi bi-trash-fill"></i>
                  </Button>
                  <Button className="button-editar" variant="warning" 
                  onClick={() => handleUpdate(item)}>
                    Editar <i className="bi bi-pencil-square"></i>
                  </Button>
                </CardListaItens>
              })
              :
              <span>Nenhum usuário cadastrado até o momento.</span>
            }
          </div>
        </ContainerPesquisa>

        {isModalVisible && (
          <ModalCadastro title={!getValues("id") ? "Cadastrar Usuário" : "Atualizar Usuário"}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3">
                <Form.Label>
                  <strong>Nome</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={errors.name ? true : false}
                  {...register("name", {
                    required: "Este campo é obrigatório.",
                  })}
                />
                {errors.name && (
                  <span className="label-error">{errors.name.message}</span>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  <strong>CodUsuário</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={errors.codusuario ? true : false}
                  {...register("codusuario", {
                    required: "Este campo é obrigatório.",
                  })}
                />
                {errors.codusuario && (
                  <span className="label-error">
                    {errors.codusuario.message}
                  </span>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  <strong>E-mail</strong>
                </Form.Label>
                <Form.Control
                  type="email"
                  isInvalid={errors.email ? true : false}
                  {...register("email", {
                    required: "Este campo é obrigatório.",
                  })}
                />
                {errors.email && (
                  <span className="label-error">{errors.email.message}</span>
                )}
              </Form.Group>

              <div>
                <Form.Group>
                  <Form.Label>
                    <strong>Permissão</strong>
                  </Form.Label>
                  <Form.Select
                    isInvalid={errors.role ? true : false}
                    {...register("role", {
                      required: "Este campo é obrigatório.",
                    })}
                  >
                    <option disabled defaultChecked>
                      Selecione...
                    </option>
                    <option>DTI</option>
                    <option>Administrador</option>
                  </Form.Select>
                  {errors.role && (
                    <span className="label-error">{errors.role.message}</span>
                  )}
                </Form.Group>
              </div>

              <div className="button">
                <Button
                  className="button-cancelar"
                  variant="outline-danger"
                  onClick={()=>{
                    resetForm()
                    showCadastroUsuarioModal()
                  }}
                >
                  Cancelar <i className="bi bi-x-circle"></i>
                </Button>
                <Button className="button-cadastrar" type="submit">
                 {!getValues("id") ? "Cadastrar" : "Atualizar"} <i className="bi bi-check-circle"></i>
                </Button>
              </div>
            </form>
          </ModalCadastro>
        )}
      </Container>
    </MainContainer>
  );
};

export { CadastroUsuario };
