import { AxiosError } from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTheme } from "styled-components";
import Swal from "sweetalert2";
import { CardListaItens } from "../../components/CardListaItens";
import { ContainerPesquisa } from "../../components/ContainerPesquisa";
import { HeaderPage } from "../../components/HeaderPage";
import { MainContainer } from "../../components/MainContainer";
import { ModalCadastro } from "../../components/ModalCadastro";
import { api } from "../../services/ApiService";
import { Container } from "./styles";

export interface IInputsLocal {
  id: string;
  name: string;
  campus: string;
}

const Local = () => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    getValues,
    reset: resetForm
  } = useForm<IInputsLocal>();
  const theme = useTheme();
  const [locais, setLocais] = useState<IInputsLocal[]>([]);
  const [localFiltro, setLocalFiltro] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showCadastroLocais = () => setIsModalVisible(!isModalVisible);

  function handleUpdate(local: IInputsLocal){
    setValue("id", local.id || "");
    setValue("name", local.name);
    setValue("campus", local.campus);
    showCadastroLocais();
  }
  
  
  
  async function onSubmit(data: IInputsLocal) {

    let request_params = {
      success_message: (!data.id ? "Cadastro realizada com sucesso." : "Cadastro atualizado com sucesso"),
      url: (!data.id ? "/local/novo" : "/local/atualizar")
    }

    try {
      const result = await api.post(request_params.url, {
        ...data,
      });
      Swal.fire({
        toast: true,
        icon: "success",
        title: "Feito!",
        text: request_params.success_message,
        confirmButtonText: "Aceitar",
        confirmButtonColor: theme.button_colors.primary,
      });
      resetForm()
      getLocal();
      {request_params.success_message == "Cadastro atualizado com sucesso"? showCadastroLocais() : <></>}
    } catch (err: AxiosError | any) {
      Swal.fire({
        toast: true,
        icon: "warning",
        title: "Atenção!",
        text: err.response
          ? err.response.data.error
          : "Falha ao tentar comunicar-se com o servidor, por favor tente novamente, se o erro persistir contate o administrador do sistema.",
        confirmButtonText: "Aceitar",
        confirmButtonColor: theme.button_colors.primary,
      });
    }
  }

  async function getLocal() {
    try {
      const result = await api.get("/local/all");
      setLocais(result.data);
    } catch (err: AxiosError | any) {
      Swal.fire({
        title: "Atenção!",
        icon: "warning",
        text: err.response
          ? err.response.data.error
          : "Erro desconhecido. Por favor contate o administrador do sistema.",
        confirmButtonColor: theme.button_colors.primary,
        toast: true,
      });
    }
  }

  async function deletarLocal(id_local: string){
    let isloading = false;

    await Swal.fire({
      title: "Atenção!",
      icon: "question",
      text: `Tem certeza que deseja excluir esse local?`,
      confirmButtonColor: theme.button_colors.primary,
      confirmButtonText: "Sim",
      denyButtonText: "Não",
      denyButtonColor: theme.button_colors.danger,
      showDenyButton: true,
      toast: true,
      preConfirm: () => executeDelete(),
    })

    async function executeDelete(){
      isloading = true;

      try{
        await api.delete(`/local/${id_local}/delete`);

        Swal.fire({
          title: "Feito!",
          icon: "success",
          text: "Local apagado com sucesso",
          confirmButtonColor: theme.button_colors.primary,
          confirmButtonText: "Aceitar",
          toast: true,
        })
        getLocal();
      } catch (err: AxiosError | any){
        Swal.fire({
          title: "Atenção!",
          icon: "warning",
          text: err.response ? "Erro! Local está vinculado a uma reserva." : "Erro desconhecido. Por favor contate o administrador do sistema.",
          confirmButtonColor: theme.button_colors.primary,
          toast: true
        })
        isloading=false;
      }
    }
  }

  useEffect(() => {
    getLocal();
  }, []);

  return (
    <MainContainer>
      <Container>
        <HeaderPage title="Locais">
          <Button id="button" onClick={showCadastroLocais}>
            Novo local <i className="bi bi-plus-circle-dotted"></i>
          </Button>
        </HeaderPage>
        <ContainerPesquisa title={"Pesquisar local"} haveresearch>
          <InputGroup className="mb-3">
            <Form.Control
              id="input-pesquisa"
              placeholder="Digite algo para procurar..."
              value={localFiltro}
              onChange={(ev) => setLocalFiltro(ev.target.value)}
            />
            <InputGroup.Text id="basic-addon2">
              <i className="bi bi-search"></i>
            </InputGroup.Text>
          </InputGroup>
          <div className="lista-reservas">
            {locais.length > 0 ? (
              locais.filter((item)=>
              item.name.includes(localFiltro) || item.campus.toLowerCase().includes(localFiltro.toLowerCase())
              )
              .map((item) => {
                return (
                  <CardListaItens
                    key={item.id}
                    titulo1="Descrição:"
                    titulo2="Campus:"
                    texto1={item.name}
                    texto2={item.campus}
                  >
                    <Button className="button-apagar" variant="outline-danger"
                    onClick={()=> deletarLocal(item.id)}>
                      Apagar <i className="bi bi-trash-fill"></i>
                    </Button>
                    <Button className="button-editar" variant="warning"
                    onClick={()=> handleUpdate(item)}>
                      Editar <i className="bi bi-pencil-square"></i>
                    </Button>
                  </CardListaItens>
                );
              })
            ) : (
              <span>Nenhum local cadastrado até o momento.</span>
            )}
          </div>
        </ContainerPesquisa>

        {isModalVisible && (
          <ModalCadastro title={!getValues("id") ? "Cadastrar Local" : "Atualizar Local"}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  <strong>Descrição</strong>
                </Form.Label>
                <Form.Control
                  isInvalid={errors.name ? true : false}
                  {...register("name", {
                    required: "Este campo é obrigatório.",
                  })}
                  type="text"
                />

                {errors.name && (
                  <span className="label-error">{errors.name.message}</span>
                )}
              </Form.Group>

              <div>
                <Form.Group>
                  <Form.Label>
                    <strong>Campus</strong>
                  </Form.Label>
                  <Form.Select
                    isInvalid={errors.campus ? true : false}
                    {...register("campus", {
                      required: "Este campo é obrigatório.",
                    })}
                  >
                    <option disabled></option>
                    <option value="Lagoa Seca">Lagoa Seca</option>
                    <option value="Saúde">Saúde</option>
                    <option value="Crajubar">Crajubar</option>
                  </Form.Select>
                  {errors.campus && (
                    <span className="label-error">{errors.campus.message}</span>
                  )}
                </Form.Group>
              </div>

              <div className="button">
                <Button
                  className="button-cancelar"
                  variant="outline-danger"
                  onClick={() => {
                    resetForm()
                    showCadastroLocais()
                  }}
                >
                  Cancelar <i className="bi bi-x-circle"></i>
                </Button>
                <Button className="button-cadastrar" type="submit">
                  {!getValues("id") ? "Cadastrar" : "Atualizar"} <i className="bi bi-check-circle"></i>
                </Button>
              </div>
            </form>
          </ModalCadastro>
        )}
      </Container>
    </MainContainer>
  );
};

export { Local };
