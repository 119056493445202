import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 100;

  .box {
    width: 700px;
    height: 75%;
    background-color: white;
    border-radius: 10px;
    max-width: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    overflow-y: scroll;
  }

  .titulo-header {
    padding: 3% 0 2% 3%;
    border-bottom: 1px solid #cfd8dc;

    h1 {
      font-size: 2rem;
      font-weight: normal;
    }
  }

  .info {
    padding: 2% 4%;
  }

  }
`;
