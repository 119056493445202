import styled from "styled-components";

export const Container = styled.div`
    padding: 5px 0 17px 0;

  .info-local {
   min-width: 70%;
    
    color: black;
  }

  .titulo{
    font-weight: 500;
    padding-bottom: 5px;
  }
  

  .button_card {
    display: flex;
    justify-content: right;
  }
  .button-apagar,
  .button-editar {
    width: 100px;
    margin-left: 10px;
  }
  .button-editar {
    color: white;
  }

`;
