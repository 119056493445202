import { AxiosError } from "axios";
import dayjs from "dayjs";
import { ReactElement, ReactNode, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { ThemeConsumer, useTheme } from "styled-components";
import Swal from "sweetalert2";
import { AppContext, EUserRole } from "../../context/ContextProvider";
import { api } from "../../services/ApiService";
import { ModalCadastro } from "../ModalCadastro";
import "./styles";
import { Container } from "./styles";

interface IReservasCard {
  numreserva: number;
  local: string;
  times: any;
  campus: string;
  equipamentos: any;
  descricao: string;
  idReserva: string;
  status: any;
  finalizado?: boolean;
  coduser: string;
  name: string;
  onReserveUpdated?: () => void;
  data_reserva: any;
  data_finalizacao: any;
}



const CardReservation = ({ numreserva, local, times, campus, equipamentos, descricao, idReserva, status, finalizado, coduser, name, onReserveUpdated, data_reserva, data_finalizacao }: IReservasCard) => {

  const { user } = useContext(AppContext);
  const theme = useTheme();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showDetalhe = () => setIsModalVisible(!isModalVisible);


  async function finalizarmontagem(id_reserva: string) {
    try {
      await api.put(`/finalizado/${id_reserva}/montagem`)

      Swal.fire({
        toast: true,
        icon: "success",
        title: "Feito",
        text: "Montagem finalizada.",
        confirmButtonText: "Aceitar",
        confirmButtonColor: theme.button_colors.primary,
      })

      onReserveUpdated && onReserveUpdated();
      setIsModalVisible(false);
    } catch (err: AxiosError | any) {
      Swal.fire({
        toast: true,
        title: "Atenção!",
        icon: "warning",
        text: err.response ? err.response.data.error : "Erro desconhecido. Por favor contate o administrador do sistema.",
        confirmButtonText: "Aceitar",
        confirmButtonColor: theme.button_colors.primary,
      })
    }
  }


  async function confirmarmontagem(time_reserve_id: string) {
    try {

      await api.put(`/montagem/${time_reserve_id}/confirmada`)

      Swal.fire({
        toast: true,
        icon: "success",
        title: "Feito",
        text: "Equipamento montado.",
        confirmButtonText: "Aceitar",
        confirmButtonColor: theme.button_colors.primary,
      })

      onReserveUpdated && onReserveUpdated()
    } catch (err: AxiosError | any) {
      Swal.fire({
        title: "Atenção!",
        icon: "warning",
        text: "Por favor, finalize todas as montagens.",
        confirmButtonColor: theme.button_colors.primary,
      })
    }
  }

  function dataFormatar(date: any){
    var strData = dayjs(date).format('DD/MM/YYYY, HH:mm')
    return strData;
  }

  return (
    <Container>
      <div id="header">
        {(() => {
          switch (status) {
            case "Aguardando":
              return <div id="header_1"><h1>{status}</h1></div>;
            case "Em montagem":
              return <div id="header_2"><h1>{status}</h1></div>
            case "Montado":
              return <div id="header_3"><h1>{status}</h1></div>;
            case "Finalizado":
              return <div id="header_4"><h1>{status}</h1></div>;
            default:
              return <div id="header_1"><h1>{status}</h1></div>;
          }
        })()}
      </div>


      <div id="dados-reserva">
        <div>
          <strong>Reserva n° </strong>
          <p>{numreserva}</p>
        </div>
        <div>
          <strong>Local:</strong>
          <p>{local}</p>
        </div>
        <div>
          <strong>Horário: </strong>
          <div>
            {
              times ?
                times.map((item: any, idx: number) => {
                  return (
                    <p className="horarios_p" key={idx}>{item.id_time}</p>
                  )
                })
                :
                <p></p>
            }
          </div>
          <div>
            <strong>Data da Reserva:</strong>
            <p>{data_reserva}</p>
         </div>

         <div>
            <strong>Solicitante:</strong>
            {name?
            <p>{name}</p>
            :
            <p>{coduser}</p>}
         </div>
        </div>
      </div>

      <div id="footer">
        <Button className="button-detalhes" onClick={showDetalhe}>
          Detalhes <i className="bi bi-list"></i>
        </Button>
      </div>


      {isModalVisible && (
        <>

          <ModalCadastro title={"Reserva"}>
            <div>
              <strong>Reserva nº:</strong>
              <p className="conteudo">{numreserva}</p>
            </div>
            <div>
              <strong>Local:</strong>
              <p className="conteudo">{local}</p>
            </div>
            <div>
              <strong>Horário:</strong>
              <div className="horarios">
                {
                  times ?
                    times.map((item: any, idx: number) => {
                      return (

                        <div className="horarios-div" key={idx}>
                          <span className="horarios_modal">{item.id_time}</span>
                          {
                            user?.role === EUserRole["Administrador"] || user?.role === EUserRole["DTI"] ?
                            (item.isMounted == false) ?
                              <Button className="button-Montado-horario" variant="success"
                                onClick={() => confirmarmontagem(item.id)}>
                                Montado <i className="bi bi-check-circle"></i>
                              </Button>
                              :
                              <div className="button_Montado">
                                <div>
                              <Button className="button-Montado-horario_dois">
                                Montado
                              </Button>
                              </div>
                              <div>
                              {user.role == EUserRole['Administrador']?
                              <p>{dataFormatar(item.updated_at)}</p>
                              :

                              <></>
                            }
                            </div>
                              </div>  
                              :
                              <></>
                          }

                        </div>

                      )
                    })
                    :
                    <p></p>
                }
              </div>
            </div>
            <div>
              <strong>Campus:</strong>
              <p className="conteudo">{campus}</p>
            </div>
            <div>
              <strong>Equipamento:</strong>
              <div className="horarios">
                {
                  equipamentos ?
                    equipamentos.map((item: any, idx: number) => {
                      return (
                        <p className="horarios_modal" key={idx}>{item.equipamento.name}</p>
                        
                      )
                    })
                    :
                    <p></p>
                }
              </div>
            </div>
            <div>
              <strong>Observação:</strong>
              <p className="conteudo">{descricao}</p>
            </div>

            <div>
              <strong>Solicitante:</strong>
              <p className="conteudo">{coduser} - {name}</p>
            </div>   

        
            <div className="data_reserva">
              <div>
                <strong>Data de Reserva:</strong>
                <p className="conteudo">{data_reserva}</p>
              </div>

                {
                  finalizado == true && user?.role == EUserRole['Administrador']?
                  <div>
                  <strong>Data de finalização:</strong>
                  <p className="conteudo">{dataFormatar(data_finalizacao)}</p>
                </div>
                :
                <></>
                }

            </div> 
              

            <div className="button">
              {  
              user?.role === EUserRole["Administrador"] || user?.role === EUserRole["DTI"]?
              <>
              {
                status === "Montado" ?
                  <>
                    {  
                    (  finalizado == false )?
                        <Button className="button-Fechado"
                          onClick={() => finalizarmontagem(idReserva)}>
                          Finalizado <i className="bi bi-check-circle"></i>
                        </Button>
                        :
                        <Button className="button-Fechado_dois">
                          Finalizado
                        </Button>

        
                    }
                  </>
                  :
                  <>
                    <Button className="button-Fechado_dois">
                      Finalizado
                    </Button>
                  </>
              }
              </>
              :
              <></>
            }
              <Button
                className="button-cancelar"
                variant="outline-danger"
                onClick={showDetalhe}
              >
                Fechar <i className="bi bi-x-circle"></i>
              </Button>
            </div>
          </ModalCadastro>
        </>
      )}



    </Container>
  );
};

export { CardReservation };
