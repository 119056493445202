// import React, { useContext, useState } from 'react';
import { Container } from "./styles";
import { MainContainer } from "../../components/MainContainer";
import { HeaderPage } from "../../components/HeaderPage";
import { ContainerPesquisa } from "../../components/ContainerPesquisa";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ModalCadastro } from "../../components/ModalCadastro";
import Swal from "sweetalert2";
import { useTheme } from "styled-components";
import { api } from "../../services/ApiService";
import { AxiosError } from "axios";
import { useContext } from "react";
import { AppContext, EUserRole } from "../../context/ContextProvider";
import { IInputsCadastroEquipamentos } from "../Equipamentos";
import { IInputsLocal } from "../Local";

export interface ILocal {
  id: string;
  name: string;
  campus: string;
  created_at: string;
  updated_at: string;
}

export interface IReserve {
  id: string;
  codusuario: string;
  nameUser: string;
  description: string;
  num_reserve: number
  id_local: string;
  isFinished: boolean;
  madeByUser: string;
  status: string;
  data: string;
  local: ILocal;
  equipamentos: [];
  times: [];
  created_at: string;
  updated_at: string;
}

export interface IInputsReserva {
  equipamentos: any;
  times: any;
  local: any;
  id_horario: string;
  id: string;
  data: Date;
  codusuario: string;
  nameUser: string;
  id_local: string;
  description: string;
  isFinished: boolean;
  num_reserve: number;
  status?: string;
  updated_at: Date;
}

const NovaReserva = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
    reset: resetForm
  } = useForm<IInputsReserva>();
  const theme = useTheme();
  const { user } = useContext(AppContext);

  const historyNavigator = useNavigate();
  const [locais, setLocais] = useState<IInputsLocal[]>([]);
  const [equipamentos, setEquipamentos] = useState<IInputsCadastroEquipamentos[]>([]);
  const [campusFiltro, setCampusFiltro] = useState("");
  const [equipamentoFiltro, setEquipamentoFiltro] = useState("");
  const equipamentoSelectRef = useRef<HTMLSelectElement | null>(null);

  const [isModalEquipamento, setIsModalEqupamento] = useState(false);
  const showSidebarEquipamento = () => setIsModalEqupamento(!isModalEquipamento);

  const [isModalLocal, setIsModalLocal] = useState(false);
  const showSidebarLocal = () => setIsModalLocal(!isModalLocal);

  const [horariosSelecionados, setHorariosSelecionados] = useState<string[]>([]);
  const [equipamentosSelecionados, setEquipamentosSelecionados] = useState<string[]>([]);

  const armazenarHorario = (id_horario: string) => {
    let horarioExiste = horariosSelecionados.findIndex(
      (item) => item === id_horario
    );

    let listaAtual = [...horariosSelecionados];
    if (horarioExiste !== -1) {
      listaAtual.splice(horarioExiste, 1);
      setHorariosSelecionados(listaAtual);
    } else {
      listaAtual.push(id_horario);
      setHorariosSelecionados(listaAtual);
    }

  };



  const armazenarEquipamento = (id_equipamento: string) => {
    if (["", null, undefined].includes(id_equipamento)) return;
    let equipamentoExiste = equipamentosSelecionados.findIndex(
      (item) => item === id_equipamento
    );

    let listaAtual = [...equipamentosSelecionados];
    if (equipamentoExiste !== -1) {
      listaAtual.splice(equipamentoExiste, 1);
      setEquipamentosSelecionados(listaAtual);
    } else {
      listaAtual.push(id_equipamento);
      setEquipamentosSelecionados(listaAtual);
    }

  };

  async function onSubmit(data: IInputsReserva) {



    let error = false;
    let error_message = "";

    if (horariosSelecionados.length <= 0) {
      error = true;
      error_message = "Você deve selecionar pelo menos um horário.";
    }

    if (equipamentosSelecionados.length <= 0) {
      error = true;
      error_message = "Você deve adicionar pelo menos um equipamento.";
    }

    if (equipamentosSelecionados.length > 0 && horariosSelecionados.length > 0) {
      let isloading = false;
      await Swal.fire({
        title: "Atenção",
        html: `Declaro para fins de <b>RESPONSABILIDADE</b>, que após receber os equipamentos devidamente testados. Serei o responsável pelo(s) mesmo(s) devendo zelar pela sua guarda, conservação e devolução no prazo devido, e nas condições de liberação, comprometendo-me a substituí-lo em caso de perda ou estrago irreparável,
        por idêntico ou similar.`,
        confirmButtonColor: theme.button_colors.primary,
        confirmButtonText: "Aceito",
        denyButtonText: "Não aceito",
        denyButtonColor: theme.button_colors.danger,
        showDenyButton: true,
        toast: true,
        preConfirm: () => execute(),
        showLoaderOnConfirm: isloading
      })

    }


    error &&
      Swal.fire({
        toast: true,
        icon: "warning",
        title: "Atenção!",
        text: error_message,
        confirmButtonText: "Aceitar",
        confirmButtonColor: theme.button_colors.primary,
      });



    async function execute() {
      try {
        const result = await api.post("/reservas/nova", {
          ...data,
          equipamentos: equipamentosSelecionados,
          horarios: horariosSelecionados
        })
        resetForm()

        Swal.fire({
          toast: true,
          icon: "success",
          title: "Feito!",
          text: "Reserva realizada com sucesso.",
          confirmButtonText: "Aceitar",
          confirmButtonColor: theme.button_colors.primary,
        });

      } catch (err: AxiosError | any) {
        Swal.fire({
          toast: true,
          icon: "warning",
          title: "Atenção!",
          text: err.response ? err.response.data.error : "Falha ao tentar comunicar-se com o servidor, por favor tente novamente, se o erro persistir contate o administrador do sistema.",
          confirmButtonText: "Aceitar",
          confirmButtonColor: theme.button_colors.primary,
        });
      }
    }
  }


  async function getLocal() {
    try {
      const result = await api.get("/local/all");
      setLocais(result.data);

   
    } catch (err: AxiosError | any) {
      Swal.fire({
        title: "Atenção!",
        icon: "warning",
        text: err.response
          ? err.response.data.error
          : "Erro desconhecido. Por favor contate o administrador do sistema.",
        confirmButtonColor: theme.button_colors.primary,
        toast: true,
      });
    }

  }

  async function getEquipamneto() {
    try {
      const result = await api.get("/equipamento/all");
      setEquipamentos(result.data)
    } catch (err: AxiosError | any) {
      Swal.fire({
        title: "Atenção!",
        icon: "warning",
        text: err.response ? err.response.data.error : "Erro desconhecido. Por favor contate o administrador do sistema.",
        confirmButtonColor: theme.button_colors.primary,
        toast: true
      })
    }

  }

  




  useEffect(() => {
    if (user !== null && user.role !== EUserRole["Administrador"]) {
      setValue("codusuario", user.codusuario )
      setValue('nameUser', user.name)
    }

    

    getLocal();
    getEquipamneto();
  }, [])

  return (
    <MainContainer>
      <Container>
        <HeaderPage title="Nova reserva" />
        <ContainerPesquisa title={""}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {
              (user !== null && user.role === EUserRole["Administrador"]) &&
              <div className="input-box">
                <label htmlFor="duedate">Solicitante</label>
                <Form.Control
                  isInvalid={errors.codusuario ? true : false}
                  {...register("codusuario", {
                    required: "Este campo é obrigatório.",
                  })}
                  type="text"
                  placeholder="Usuário que solicitou a reserva"
                />
                {errors.codusuario && (
                  <span className="label-error">{errors.codusuario.message}</span>
                )}
              </div>
            }

            <div className="input-box">
              <label htmlFor="duedate">Selecione o dia</label>
              <Form.Control
                isInvalid={errors.data ? true : false}
                {...register("data", {
                  required: "Este campo é obrigatório.",
                })}
                type="date"
                placeholder="Due date"
              />
              {errors.data && (
                <span className="label-error">{errors.data.message}</span>
              )}
            </div>

            <div className="input-box">
              <label>Selecione o Campus</label>
              <Form.Select
                onChange={(event) => setCampusFiltro(event.target.value)}
              >
                <option defaultChecked>Selecione...</option>
                <option value="Lagoa Seca">Lagoa Seca</option>
                <option value="Saúde">Saúde</option>
                <option value="Crajubar">Crajubar</option>
              </Form.Select>
            </div>

            <div className="input-box">
              <label>Selecione o Local</label>
              <Form.Select
                isInvalid={errors.id_local ? true : false}
                {...register("id_local", {
                  required: "Este campo é obrigatório.",
                })}
              >
                {locais.length > 0 && campusFiltro !== "" ? (
                  locais.sort((prev,next)=>{
                    let a = prev.name.toUpperCase(),
                        b = next.name.toUpperCase()
          
                    return a == b? 0: a > b ? 1 : -1;
                  })
                    .filter((item) => item.campus === campusFiltro)
                    .map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })
                ) : (
                  <>
                    <option disabled defaultChecked>
                      Selecione um campus...
                    </option>
                  </>
                )}
              </Form.Select>
              {errors.id_local && (
                <span className="label-error">{errors.id_local.message}</span>
              )}
            </div>

            <div className="input-box">
              <h2 className="text-horario">Marque os horários</h2>
              <div>
                {(() => {
                  let renderizados = 0;
                  let options = [
                    "AB - Manhã",
                    "AB - Tarde",
                    "AB - Noite",
                    "CD - Manhã",
                    "CD - Tarde",
                    "CD - Noite",
                  ].map((item, idx) => {
                    renderizados += 1;
                    return (
                      <>
                        {renderizados === 4 ? <br /> : null}
                        <Form.Check
                          key={idx}
                          inline
                          className="checkbox-horario"
                          label={item}
                          type="checkbox"
                          checked={horariosSelecionados.includes(item)}
                          onChange={() => armazenarHorario(item)}
                        />
                      </>
                    );
                  });
                  return options;
                })()}
              </div>
            </div>

            <div className="incluir-equipamento">
              <h2 className="label-equipamento">Equipamentos</h2>
              <Button id="btn" onClick={showSidebarEquipamento}>
                Adicionar <i className="bi bi-plus-circle-dotted"></i>
              </Button>
              {isModalEquipamento && (
                <ModalCadastro title={"Selecionar Equipamento"}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      <strong>Procurar</strong>
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        onChange={(event) =>
                          setEquipamentoFiltro(event.target.value)
                        }
                      />
                      <InputGroup.Text id="basic-addon2">
                        <i className="bi bi-search"></i>
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <div className="input-box">
                    <label>Selecione o Equipamento</label>
                    <Form.Select ref={equipamentoSelectRef}
                      isInvalid={errors.equipamentos ? true : false}>
                      {equipamentos.length > 0 && campusFiltro !== "" ? (
                        equipamentos.sort((prev,next)=>{
                          let a = prev.name.toUpperCase(),
                              b = next.name.toUpperCase()
                
                          return a == b? 0: a > b ? 1 : -1;
                        })
                          .filter((item) => {
                            let campus_filtro = true,
                              equip_filtro = true,
                              equip_ja_selecionado = true;

                            if (item.campus !== campusFiltro)
                              campus_filtro = false;

                            if (equipamentosSelecionados.includes(item.id))
                              equip_ja_selecionado = false;

                            if (equipamentoFiltro !== "") {
                              if (!item.name.includes(equipamentoFiltro))
                                equip_filtro = false;
                            }

                            return (
                              campus_filtro &&
                              equip_filtro &&
                              equip_ja_selecionado
                            );
                          })
                          .map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })
                      ) : (
                        <>
                          <option disabled defaultChecked>
                            Selecione...
                          </option>
                        </>
                      )}
                    </Form.Select>
                  </div>
                  <div className="button">
                    <Button
                      className="button-cancelar"
                      variant="outline-danger"
                      onClick={showSidebarEquipamento}
                    >
                      Fechar <i className="bi bi-x-circle"></i>
                    </Button>
                    <Button
                      className="button-cadastrar"
                      onClick={() => {
                        if (equipamentoSelectRef.current !== null) {
                          armazenarEquipamento(
                            equipamentoSelectRef.current.value
                          );
                        }
                      }}
                    >
                      Adicionar <i className="bi bi-check-circle"></i>
                    </Button>
                  </div>
                </ModalCadastro>
              )}
            </div>

            <div className="tabela-equipamento">
              <Table className="table-hover">
                <tbody>
                  {(() => {
                    let equipamentos_para_exibir = equipamentos
                      .filter((item) =>
                        equipamentosSelecionados.includes(item.id)
                      )
                      .map((item) => item);

                    let item_para_renderizar = equipamentos_para_exibir.map(
                      (item) => {
                        return (
                          <tr>
                            <td className="col-md-11">{item.name}</td>
                            <td>
                              <Button
                                className="delete-equipamento"
                                variant="danger"
                                onClick={() => armazenarEquipamento(item.id)}
                              >
                                <i className="bi bi-dash-circle-dotted"></i>
                              </Button>
                            </td>
                          </tr>
                        );
                      }
                    );

                    if (item_para_renderizar.length <= 0) {
                      return (
                        <tr>
                          <td className="col-md-11">
                            <span>Nenhum equipamento selecionado.</span>
                          </td>
                        </tr>
                      );
                    }
                    return item_para_renderizar;
                  })()}
                </tbody>
              </Table>
            </div>

            <div className="input-box">
              <label htmlFor="description">Observação</label>
              <Form.Control
                as="textarea"
                rows={3}
                isInvalid={errors.description ? true : false}
                {...register("description")}
              />
              {errors.description && (
                <span className="label-error">
                  {errors.description.message}
                </span>
              )}
            </div>

            <div className="form-button">
              <Button
                variant="outline-danger"
                onClick={() => {
                  historyNavigator("/home")
                  resetForm()
                }}
              >
                Cancelar <i className="bi bi-x-circle"></i>
              </Button>
              <Button id="btn" type="submit">
                Reservar <i className="bi bi-plus-circle-dotted"></i>
              </Button>
            </div>
          </form>
        </ContainerPesquisa>
      </Container>
    </MainContainer>
  );
};

export { NovaReserva };
