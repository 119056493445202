import React, { useContext } from 'react';
import { useLocation, Navigate } from "react-router-dom";
import { AppContext, EUserRole } from '../context/ContextProvider';

interface IPrivateRouteProps {
  children: JSX.Element;
  rolesAllowed?: EUserRole[];
}

function PrivateRoute({ children, rolesAllowed }: IPrivateRouteProps) {

  let location = useLocation();

  const { user } = useContext(AppContext);

  const token = sessionStorage.getItem(String(process.env.REACT_APP_SESSION_KEY));

  if (!user || !token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if ((rolesAllowed !== undefined && rolesAllowed.length > 0) && user !== null) {
    if (!rolesAllowed.includes(user.role)) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
  }

  return children;
}

export default PrivateRoute;
