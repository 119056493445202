import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import './styles';
import { ButtonPrimary, BaseButton, ButtonSuccess, ButtonDanger, ButtonDangerOutline, ButtonWarning } from './styles';

interface IButtonComponentProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: string;
    children?: ReactNode;
    minHeight?: string;
    minWidth?: string;
}

const ButtonComponent = ({ variant, children, ...rest }: IButtonComponentProps) => {

    switch (variant) {
        case "warning":
            return <ButtonWarning {...rest}>
                {children}
            </ButtonWarning>
        case "danger":
            return <ButtonDanger {...rest}>
                {children}
            </ButtonDanger>
        case "danger-outline":
            return <ButtonDangerOutline {...rest}>
                {children}
            </ButtonDangerOutline>
        case "primary":
            return <ButtonPrimary {...rest}>
                {children}
            </ButtonPrimary>
        case "success":
            return <ButtonSuccess {...rest}>
                {children}
            </ButtonSuccess>
        default:
            return <BaseButton {...rest}>
                {children}
            </BaseButton>
    }
}

export { ButtonComponent };
