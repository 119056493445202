import styled from "styled-components";

export const Container = styled.div`
  background-color: #f5f5f5;

  flex-grow: 1;
  width: 100%;
  padding: 20px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .text-horario{
    padding: 10px 0;
  }

  .form-date {
    width: 100%;
    padding-bottom: 30px;
  }
  .form-novareserva{
    width: 100%;
    padding-top: 18px;
  }
  .checkbox-horario{
    padding-right: 40px;
    padding-bottom: 10px;
  }

  .form-textarea{
    padding: 20px 0;
  }
  .incluir-equipamento{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;

    .bi-search{
      color: #ffffff;
    }
    #basic-addon2 {
      background-color: #29b6f6;
    }
    #input-pesquisa {
      border: 1px solid #cfd8dc;
    }

    span {
      cursor: pointer;
    }
  }
  .label-equipamento{
    margin-bottom: -20px;
    vertical-align: middle;
  }
  
  td{
    vertical-align: middle;
  }
  .tabela-equipamento{
    overflow-y: scroll;
    max-width: 994px;
    min-height: 250px;
    max-height: 250px;
    border: 1px solid #CFD8DC;
    border-radius: 4px;
  }
  .form-button{
    display: flex;
    justify-content: right;
  }
  
  #btn{
    background-color: #26A69A;
    margin-left: 10px;
    border: 0;
  }

  .delete-equipamento{
    background-color: #F44336;
    font-size: 20px;
    padding: 2px 8px;
  }

  .label-error{
    font-weight: 500;
    font-size: 12px;
    color: ${props => props.theme.button_colors.danger};
    margin: 5px 0;
  }

  .input-box{
    margin: 10px 0;
    width: 100%;

    display: flex;
    align-items: flex-start;
    flex-flow: column nowrap;

    label{
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 5px;
    }
  }

  .box-error{
    border-radius: 0.25rem;
    padding: 3px;
    border: 1px solid ${props => props.theme.button_colors.danger};
  }

  .button{
    position: absolute;
    bottom: 20px;
    right: 30px;
    margin-top: 60px;
    display: flex;
    justify-content: right;
    
    
    .button-cancelar{
      margin-right: 10px;
      
    }

    .button-cadastrar{
      color: white;
      background-color: #26A69A;
      border: 0;
    }
`;
