import { ReactNode } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Container } from "./styles";

interface ProTypes {
  title: string;
  haveresearch?: boolean;
  children: ReactNode;
}


const ContainerPesquisa = ({ title, haveresearch, children }: ProTypes) => {

  return (
    <Container>
      {haveresearch && (

        <div className="pesquisa">
          <h2>{title}</h2>
        </div>

      )}
      {children}
    </Container>
  );
};

export { ContainerPesquisa };