import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(function (config) {

    if (config.url !== '/session' && config.headers !== undefined) {

        const token = sessionStorage.getItem(String(process.env.REACT_APP_SESSION_KEY));

        config.headers['authorization'] = `Bearer ${token}`;

        return config;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    if (error.response.status === 401) {
        sessionStorage.removeItem(String(process.env.REACT_APP_SESSION_KEY));
        window.location.replace('/session-expired');
    }

    return Promise.reject(error);
});

export { api };