import styled from "styled-components";

export const Container = styled.div`
  background-color: #f5f5f5;

  flex-grow: 1;
  width: 100%;
  padding: 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  #basic-addon2 {
    background-color: #29b6f6;
    cursor: pointer;
    i {
      color: #ffffff;
    }
  }
  .label-error {
    font-weight: 500;
    font-size: 12px;
    color: ${(props) => props.theme.button_colors.danger};
    margin: 5px 0;
  }

  .lista {
    width: 100%;
    
  }

  .button{
    position: absolute;
    bottom: 20px;
    right: 30px;
    margin-top: 60px;
    display: flex;
    justify-content: right;
    
    
    .button-cancelar{
      margin-right: 10px;
      
    }

    .button-cadastrar{
      color: white;
      background-color: #26A69A;
      border: 0;
    }
`;
