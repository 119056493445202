import { ReactNode } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Container } from "./styles";

interface ProTypes {
  title: string;
  children: ReactNode;
}

const ModalCadastro = ({title, children}: ProTypes) => {
 

  return (
    <Container>
      <div className="box">
        <div className="titulo-header">
          <h1>{title}</h1>
        </div>
        <div className="info">
        {children}
        </div>
      </div>
    </Container>
  );
};

export { ModalCadastro };
