import React, { useContext, useState } from 'react';
import { MainContainer } from '../../components/MainContainer';
import { AppContext, EUserRole } from '../../context/ContextProvider';
import { HomeAdmin } from './HomeAdmin';
import { HomeColaborador } from './HomeColaborador';
import { Container } from './styles';

const Home: React.FC = () => {

  const { user } = useContext(AppContext);

  return (
    <MainContainer>
      <Container>
        {
          (() => {
            switch (user?.role) {
              case EUserRole["Administrador"]:
                return <HomeAdmin />;
              case EUserRole["Professor/Colaborador"]:
                return <HomeColaborador />
              case EUserRole["DTI"]:
                return <HomeAdmin />
              default:
                return;
            }
          })()
        }
      </Container>
    </MainContainer>
  );
}

export { Home };
